import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import {
  Auth,
  LoginRequest,
  LoginResponse,
  SignupRequest,
  SignupResponse,
} from 'src/app/interfaces/auth/auth';
import { Wallet } from 'src/classes/Finantial';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements Auth {
  private userSessionData = signal<any>([]);

  constructor(private http: HttpClient, private router: Router) {}

  public login = (body: LoginRequest): Observable<LoginResponse> => {
    return this.http
      .post<LoginResponse>(`${environment.api}/auth/login`, body)
      .pipe(tap((response) => this.saveUserData(response)));
  };

  public signup = (body: SignupRequest): Observable<SignupResponse> => {
    return this.http
      .post<SignupResponse>(`${environment.api}/auth/signup`, body)
      .pipe(tap((response) => this.saveUserData(response)));
  };
  public logout = () => {
    this.userSessionData.set([]);
    localStorage.removeItem('user');
    localStorage.removeItem('wallet');
    this.router.navigate(['/login']);
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  };

  public getUserData = () => {
    return this.http.get<LoginResponse>(`${environment.api}/auth/me`).pipe(
      tap((response) => {
        this.saveUserData(response);
      })
    );
  };

  public isLoggedIn = (): string | null => {
    return localStorage.getItem('user');
  };

  public saveUserData = (userData: LoginResponse | SignupResponse): void => {
    localStorage.setItem('user', JSON.stringify(userData));
    this.userSessionData.set(userData);
  };

  get readUserSessionData() {
    return this.userSessionData.asReadonly();
  }

  get readUserWallet(): Wallet {
    return JSON.parse(localStorage.getItem('wallet'));
  }
}
