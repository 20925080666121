import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {
    this.initSplash();
    this.initStatusBar();
  }

  async initSplash(){
    await SplashScreen.show({
      autoHide: true,
      showDuration: 3000,
    });
  }

  async initStatusBar(){
    StatusBar.setBackgroundColor({
      color: '#2a1674'
    });
  }
}
