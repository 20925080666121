import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { LoginResponse } from 'src/app/interfaces/auth/auth';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authSrv: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (['/login', '/signup', '/home'].includes(state.url)) {
      return this.validateLoginAndRegister();
    }

    return this.authSrv.getUserData().pipe(
      map((user) => {
        if (!user) {
          this.router.navigate(['/login']);
          return false;
        }

        if (!this.handleUserData(user, state.url)) {
          return false;
        }

        return true;
      }),
      catchError((error) => {
        console.error('Error fetching user data:', error);
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }

  validateLoginAndRegister(): boolean {
    if (
      this.authSrv.isLoggedIn() != null ||
      this.authSrv.readUserSessionData() != false
    ) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }

  validateDashboardAccess(): boolean {
    if (
      this.authSrv.isLoggedIn() != null ||
      this.authSrv.readUserSessionData() != false
    ) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  private handleUserData(user: LoginResponse, url: string): boolean {
    if (url === '/dashboard/helmet-builder') {
      return true;
    }

    if (!user.imageProfile) {
      this.router.navigate(['/dashboard/helmet-builder']);
      return false;
    }

    if (url === '/dashboard/draft') {
      if (user.drafted) {
        this.router.navigate(['/dashboard']);
        return false;
      }
      return true;
    }
    if (!user.drafted) {
      this.router.navigate(['/dashboard/draft']);
      return false;
    }

    return true;
  }
}
