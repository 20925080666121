import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authSrv: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let session = this.authSrv.isLoggedIn();
    let user = JSON.parse(session as any);
    const url = request.url; 
    if (session) {
      const authReq = request.clone({
        setHeaders: { Authorization: `Bearer ${user.accessToken}` },
      });
      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || url.includes('api/v1/auth/me')) {
            this.authSrv.logout();
            this.router.navigate(['/login']);
          }
          return throwError(() => error);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
