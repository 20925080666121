import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import Hammer from 'hammerjs';
import 'hammerjs';
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    pinch: { enable: true }, // Habilitar el gesto rotate (si lo necesitas)
    pan: { enale: true }, // Habilitar el gesto pan
  };
}
